@import '../../styles/variables.scss';

.myAccount {
    .main-header {
        background-color: $white;
        padding-top: 120px;
        padding-bottom: 40px;

        .title {
            color: $primary;
        }
    }

    section.tabs {
       .tabContentTitle {
           margin-top: 20px;
       }

       .nav-tabs {
           margin-top: -52px;
           position: relative;
        //    top: -65px;
       }

       .tab-content {
           padding-bottom: 60px;
           padding-top: 40px;
       }

       .container > .row {
           margin-top: -65px;
       }
    }

    .main {
        background-color: $gray_light;

        .container {
            position: relative;
            // top: -62px;
            padding-bottom: 30px;
        }

        .tab-content {
            padding-bottom: 0 !important;
        }
    }
}


@media only screen and (max-width: 991px) {
    .myAccount {
        .main-header {
            padding-bottom: 90px;
        }
    }
}
@media only screen and (max-width: 576px) {
    .myAccount {
        .main-header {
            padding-bottom: 90px !important;
            .title {
                margin-bottom: 30px;
            }
        }
    }
}
