@import '../../../styles/variables.scss';

#detailOrderDialog {
    font-size: 12px;
    .detailOrderContent {
        position: relative;
        min-height: 50px;
        overflow: auto;
        max-height: 80vh;

        .loadingDetailOrder {
            right: 50%;
            transform: translateX(50%);
        }
    }

    .hr-divider {
        width: 95%;
    }

    .summaryPriceWithoutVat {
        padding-left: 5px;
        font-size: 16px;
    }
    .summaryPriceWithVat {
        padding-left: 5px;
        font-size: 20px;
        color: $primary;
    }

    .detailOrderTableContainer {
        overflow: auto;
    }
    .detailOrderTable {
        
        margin-bottom: 25px;
        width: 100%;
        
        thead {
            th {
                background-color: $primary;
                color: $white;
                line-height: 3;

                &:last-child {
                    padding-right: 10px
                }

                &:first-child {
                    padding-left: 10px;
                    min-width: 200px;
                }

                &:nth-child(3) {
                    min-width: 130px;
                }
                &:nth-child(4) {
                    min-width: 150px;
                }
            }
        }

        tbody {
            tr:not(:last-child) {
                border-bottom: 1px solid $divider;
            }
            td {
                line-height: 2;
                &:last-child {
                    padding-right: 10px
                }

                &:first-child {
                    padding-left: 10px;
                }
            }
        }

        
    }
}