@import '../../../styles/variables.scss';

.page-loader-custom {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 999999;
    background-color: $white;

    .lds-dual-ring {
        top: 50%;
        right: 50%;
        transform: translateX(50%);
    }
}

// .page-loader-custom:before {
//     font-family: 'LinearIcons';
//     speak: none;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 40px;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     content: "\e810";
//     position: fixed;
//     font-size: 40px;
//     left: 50%;
//     top: 50%;
//     z-index: 999999;
//     width: 40px;
//     height: 40px;
//     text-align: center;
//     margin-left: -20px;
//     margin-top: -20px;
//     -webkit-animation: fa-spin 2s infinite linear;
//     animation: fa-spin 2s infinite linear;
//     color: $primary;
//     -moz-transition: all 0.3s;
//     -o-transition: all 0.3s;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
// }

.page-loader-custom.loaded {
    width: 0;
    left: 0;
    -moz-transition: all .5s cubic-bezier(0.8, 0.2, 0.2, 0.9);
    -o-transition: all .5s cubic-bezier(0.8, 0.2, 0.2, 0.9);
    -webkit-transition: all .5s cubic-bezier(0.8, 0.2, 0.2, 0.9);
    transition: all .5s cubic-bezier(0.8, 0.2, 0.2, 0.9);
}

.page-loader-custom.loaded:before {
    opacity: 0;
    display: none;
}
