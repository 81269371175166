@import '../../../styles/variables.scss';

.loging {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
   
.facebookBtn {
    font-size: 80% !important;
}
@media (max-width: 767px) {

    .facebookBtn {
        font-size: 100% !important;
    }
} 

.myAccount {

    &.login-wrapper {
        max-width: 300px;
    }

    a {
        text-align: center;
        font-size: 14px !important;
        
        &:hover {
            cursor: pointer;
            color: $primary;
        }
    }

    .username {
        text-align: center;
        font-size: 20px;
        // word-break: break-all;
    }
}

.login-wrapper.open {
    background-color: $white;
    border: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    a:hover {
        cursor: pointer;
    }
}

#resetPasswordModal {
    .form-control {
      &:focus {
        box-shadow: none;
      }
  
      &.wrong {
        border-color:  $red;
      }
    }
}

.requestForResetPassword {
    .modal-body {
        min-height: 50px;
    }

    .requestForResetPasswordLoader {
        position: absolute;
        left: 50%;
    }
}