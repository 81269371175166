@import '../../../styles/variables.scss';

.updateShoppingCart {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

nav .cart-wrapper {
    width: auto;
    background-color: $white;
    border: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);

    .checkout {
        background-color: $white;
    }
}

nav .cart-wrapper .cart-block.cart-block-item {
    border: 1px solid $divider;
}

nav .cart-wrapper.open .cart-block.cart-block-footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
 
}

nav .cart-wrapper.open .cart-block.cart-block-footer {
    strong {
        font-size: 1.25rem;
    }
}

nav .cart-wrapper.open .cart-block.cart-block-footer-price {
    strong {
        font-size: 1.75rem;
    }
    padding: 0 !important;
}

.cart-wrapper.open.empty {
    width: auto;
    max-width: 220px;
    padding: 20px !important;
    border: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);

    span, .checkout {
        background-color: $white;
    }

   
}