//@import '../../../index.scss';

.custom-input {
    margin-bottom: 10px !important;
}
.input-text::after {
    //border-bottom-color: $primary !important;
}

.input-input::after {
    border-bottom-color: #777777 !important;
}

.hidden {
    display: none;

}

.show {
    display: block;
    //color: $danger !important;
}

.w-100 {
    width: 100%;
}

.mr-10 {
    margin-right: 10px !important;
}

.text-right {
    input {
        text-align: right;
    }

}
