@import '../../styles/variables.scss';

.shoppingCart {
    section.main-header {
        background-color: $white;
        padding-top: 110px !important;
    }

    section.checkout {
        background-color: $gray_light;
    }

    .step-wrapper {
        padding-top: 25px;

        @media screen and (min-width: 1200px) {
            padding-top: 75px;
        }

        @media screen and (max-width: 991px) {
            padding-top: 0;
        }
    }

    .h2 {
        color: $primary;
    }

    .step-wrapper .stepper > ul > li {
        span {
            color: $gray;
        }

        &:before {
            background-color: $gray;
        }
    }

    .step-wrapper {
        .stepper {
            ul {
                li {
                    &.active {
                        span {
                            color: $primary;
                        }
                        &::before {
                            background-color: $primary;
                        }
                    }
                    &.current {
                        span {
                            color: $primary;
                        }
                        &::before {
                            background-color: $primary;
                        }
                    }
                    span {
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.upperControls {
    display: flex;
    justify-content: flex-end;
}
