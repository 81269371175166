@import '../../../styles/variables.scss';

span.pull-right {
    div {
        display: flex;
        justify-content: flex-end;
    }
}

.comment-user {
    svg {
        fill: $gray;
    }
}

.comment-desc.edit {
    margin-left: 0 !important;
}

.comment-block {
    padding: 40px;
    margin: 0 !important;
}

.editReview {
    width: 100%;
}

.editableStars {
    transform: scale(1.5);
    right: 20px;
    position: relative;
}

.btn-clean-blue {
    border-color: $primary;
    color: $primary;

    &:hover{
        background-color: $primary;
        color: $white;
    }
}

.comments {
    .comment-user {
        div {
            img.productImage {
                border-radius: 0;
            }
        }
    }
}