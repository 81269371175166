@import '../../../styles/variables.scss';

.products {
    background-color: $gray_light;
}

@media only screen and (min-width: 768px) {
    .products article {
        .image {
            img {
                padding: 40px 20px 20px 20px;
                max-height: 90%;
                width: auto;
                display: block;
                // margin: 0 auto;
                max-width: 90%;
                position: absolute;
                // position: relative;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }

            height: 300px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .products {
        .container {
            margin-top: 0.75em;

            header {
                margin-bottom: 1.5em;
            }
        }
        article {
            .image {
                height: 200px;
                img {
                    width: auto;
                    padding-top: 40px;
                    height: 100%;
                    margin: 0 auto;
                    display: block;
                }
            }

            .text {
                position: relative;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .products {
        article {
            .image {
                height: 150px;

                img {
                    height: 150px;
                    width: auto;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
