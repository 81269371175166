@import '../../styles/variables.scss';

.filter-box {
    .title {
        text-align: left;

        span {
            margin-left: 5px;
        }
    }
}

.cateogry {
    .main-header {
        background-color: $white;
        padding-top: 110px;
        position: relative;
        z-index: 1;

        .title {
            color: $primary;
        }

        .breadcrumb {
            white-space: pre-wrap;
            .icon-home {
                color: $primary;
            }
        }
    }

    .sort-bar {
        position: relative;
        top: 15px;
    }

    .products {
        padding-top: 30px;
    }
}
div.price-filter {
    padding: 15px 8px !important;
}

.customPriceSlider {
    .rc-slider-track,
    .rc-slider-handle {
        background-color: $primary;
    }
}

.filterLoader {
    right: 50%;
    position: absolute;
    transform: scale(1.5);
}

.emptyCategory {
    text-align: center;
    font-size: 20px;
    color: $gray;
}

.breadcrumb {
    li {
        a {
            color: $gray !important;

            &:hover {
                color: $primary !important;
                cursor: pointer;
            }
        }
    }
}
.maxLabel {
    position: relative;
    right: 10px;
}

.noProductsWithParams {
    p {
        text-align: center;
    }

    i.fa {
        display: block;
        text-align: center;
        font-size: 15rem;
    }
}

.products {
    position: relative;

    &.emptyList {
        padding-bottom: 200px;
    }

    .nextPageLoader {
        bottom: -20px;
        right: 50%;
        top: auto;
    }
}

#sort-price {
    &:hover {
        cursor: pointer;
    }
}

@media only screen and (max-width: 992px) {
    .cateogry {
        .products {
            padding-top: 40px;
            .noProductsWithParams {
                min-height: 200px;
            }

            .sort-bar {
                margin-bottom: 20px;
            }
        }

        .main-header {
            padding-top: 95px !important;

            header {
                margin: 0;
            }
        }

        .products.emptyList {
            height: 100vh;
        }
    }

    .phoneFilterMenu {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;

        .close-menu {
            i {
                font-size: 20px;
            }

            &:hover {
                cursor: pointer;
            }
        }
        .filtertMenuTitle {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .cateogry {
        .sort-bar {
            .sort-options {
                margin-top: 20px !important;
            }
        }

        .emptyCategory {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .cateogry {
        .sort-bar {
            span,
            select {
                font-size: 13px !important;
            }
            padding-bottom: 20px;
        }

        .main-header {
            padding-top: 150px !important;
            margin-bottom: 20px !important;
            header {
                z-index: 10 !important;
                position: relative !important;
            }
        }

        .breadcrumb {
            white-space: pre-wrap;
        }
    }

    section.products {
        padding-bottom: 20px !important;

        .emptyCategory {
            margin-bottom: 0;
        }
    }
}

.renderChips {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem 1rem !important;
    margin-top: -0.5rem;
}
.chip span {
    background-color: $primary_dark;
    color: #fff;
    border-radius: 10px;
    padding: 3px 5px;
    display: inline-block;
    margin: 2px 5px;
    font-size: 1rem;
    text-transform: uppercase;
}
@media (max-width: 767px) {
    .chip span {
        font-size: 1rem;
    }
    .searchTermWrapper {
        padding: 0 1.5rem 0 !important;
    }
}
.chip .fa-times {
    margin-left: 10px;
}
.fa-times:hover {
    cursor: pointer;
}

@media screen and (min-width: 1200px) {
    header {
        .breadcrumb-inverted {
            // @rs added padding to clear the menubar
            margin-top: 3.5em !important;
        }

        .breadcrumb {
            margin-top: 4.1em !important;
        }
    }
}
