@import '../../../styles/variables.scss';

.comment-wrapper.one {
    div.comment-block:nth-child(n+2) {
        display: none;
    }
    div.comment-block {
        border-bottom: none;
    }
}

.comment-wrapper.show {
    div.comment-block:nth-child(n+2) {
        animation: resize;
        animation-duration: .5s;
        display: block;
    }
}
.myRating {
    .fa.fa-star {
        color: $primary;
        font-size: 26px;
    } 
    .fa.fa-star-o {
        font-size: 26px;
    }
}


@keyframes resize {
    from {
        height: 0;
    }
    to {
        height: 100%
    }
}
.rating {
    padding-top: 40px;
    display: flex;
    justify-content: space-evenly;

    .averageRating {
        display: flex;
        flex-direction: column;

        .value {
            text-align: center;
        }
    }

    .perNumStars {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.total_reviews {
    text-align: center;
}

.comment-reply-message {
    text-align: center;
}

.updateReview {
    right: 50%;
}

.noReviews {
    font-size: 2rem;
}

.commentsEmpty {
    // padding-top: 20px;

    .comment-add {
        padding-top: 20px;
    }
}

.grayBox {
    max-width: 100px;
    background-color: $gray;
    display: inline-block;
    height: 8px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0.75;
}

@media only screen and (min-width: 769px) {
    .rating {
        .averageRating {
    
            span:not(.total_reviews) {
                font-size: 50px !important;
            }
        }
    
    }
}

@media only screen and (max-width: 768px) {
    .rating {
        .averageRating {
    
            span {
                font-size: 20px !important;
            }

            span:nth-child(2) {
                text-align: center;
            }

            .total_reviews {
                font-size: 11px !important;
            }
        }

        .perNumStars {
            .grayBox {
                height: 3px;
                vertical-align: middle;
            }
        }
    
    }
}