@import '../../../styles/variables.scss';

.mt-20 {
    margin-top: 20px !important;
}

.countries.aboutMe {

    .form-group {
        padding-top: 0;
        padding-bottom: 0;
        select {
            height: 48px;
        }


        select.classic2 {
            background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
                linear-gradient(135deg, #000000 50%, transparent 50%);
            background-position: calc(100% - 17px) calc(1em + 5px),
                calc(100% - 12px) calc(1em + 5px);
            background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
            background-repeat: no-repeat;
        }
    }
}

@media screen and (min-width: 1200px) {
    .myAccount {
        padding-top: 75px;
    }
}
