@import '../../../styles/variables.scss';

select {

    /* styling */
    background-color: $white;
    border: none;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0 1.5em 0 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }


  /* arrows */

  select.classic {
    background-image:
      linear-gradient(45deg, transparent 50%, $black 50%),
      linear-gradient(135deg, $black 50%, transparent 50%);
    background-position:
    calc(100% - 7px) calc(1em - 5px), calc(100% - 2px) calc(1em - 5px);
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }

  select.classic:focus {
    background-image:
    linear-gradient(45deg, transparent 50%, $black 50%),
    linear-gradient(135deg, $black 50%, transparent 50%);
    background-position:
    calc(100% - 7px) calc(1em - 5px), calc(100% - 2px) calc(1em - 5px);
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }

.cart-block.cart-block-footer.giftsBox {
    margin-top: 5px !important;
    margin-bottom: 5px !important;


    & > div:nth-child(1) {
        width: 100% !important;
    }
}

.checkbox.giftsRadioInput {
    display: flex;
    label {
        display: flex;
        justify-content: flex-end;
        padding-left: 0 !important;
        &::before {
            position: relative !important;
        }

        .giftImage {
            max-width: 30px;
            max-height: 30px;
            margin: 0 10px;
        }
    }
}

.isDiscountApplied {
    display: flex;
    flex-direction: column;
    justify-content: center;

    small {
        font-size: 1rem;
        font-style: italic;
        color: $primary;
    }
}
.coupon-code {
  max-width: 250px;
  margin-left: auto;
}
.coupon-info {
  margin-right: 15px;
}

.coupon-form-group {
  display: flex;

  button {
    border-left: 0;
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) {
    .cart-block-footer {
        padding: 15px !important;
    }

    .cart-block-footer-price {
        .h4.title {
            text-align: right;
            color: $primary;
        }
    }
}

@media only screen and (max-width: 768px) {
    .checkout {
        .cart-block.cart-block-footer {
            margin-bottom: 0 !important;
        }

        .cart-block.cart-block-footer {
            &.cart-block-footer-price {
                padding-left: 10px;
                padding-right: 10px;

                .h4.title {
                    text-align: right;
                    color: $primary;
                    margin-bottom: 0;
                }
            }
        }
    }
}
