//bekoshop
$primary: #0391da;
$primary_dark: #0189cf;
$primary_dim: #74ccff;
//elica
//$primary: #a6cbd1;
//$primary_dark: #94a9c0;
$white: #ffffff;
$gray: #757575;
$shade: #c2bfbf;
$black: #000000;
 //$gray_light: #e9e9e9; //farba pozadia (napr. druha sekcia na stranke, rychli nahlad(header a footer))
$gray_light: #fafafa;
$red: #e71d36;
$divider: #ddd;
$stars: #efbd56;
$orange: #ff7800;
$green: #28a745;
