@import '../../../styles/variables.scss';

nav .navigation-top > ul > li > a {
    color: $gray;

    &:hover {
        cursor: pointer;
    }
}
nav .navigation > ul {    
    border-bottom-color: $divider !important; 
}

nav .navigation-top > ul > li a.dialog {

    
    svg {
        fill: $gray;

        &:nth-child(2) {
            display: none;
        }
    }

    &.open {
        svg {
            &:nth-child(2) {
                display: initial;
            }

            &:nth-child(1) {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    nav .navigation.navigation-top {
        background-color: $white;
    }
}