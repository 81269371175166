@import '../../../styles/variables.scss';

.text-left {
    .h1 {
        text-align: left;
    }
}

.oneBanner {
    position: relative;
    z-index: 0;
}

.banner-section {
    padding-top: 110px;
    padding-bottom: 0;

    .item {
        height: auto;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;

        .img {
            // visibility: hidden;
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
        }
    }
}

// @rs
// vertical align banner positions
.jc1,
.jc2,
.jc3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.jc4,
.jc5,
.jc6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jc7,
.jc8,
.jc9 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.overlay {
    //background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    // @rs
    // horizontal align banner positions
    .ac1,
    .ac4,
    .ac7 {
        color: white;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .ac2,
    .ac5,
    .ac8 {
        color: white;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .ac3,
    .ac6,
    .ac9 {
        color: white;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media only screen and (max-width: 668px) {
    .banner-section {
        padding-top: 69px;
        padding-bottom: 0;

        .item {
            // height: calc(100vh - 69px);
            height: auto;

            .overlay {
                padding: 0;

                .h1.title,
                .bannerSubtitle {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .banner-section {
        padding-top: 69px;
        padding-bottom: 0;

        .item {
            // height: calc(100vh - 69px);
            height: auto;

            .overlay {
                padding: 0;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .banner-section {
        padding-top: 150px;
    }
}

@media only screen and (max-width: 526px) {
    .banner-section.mini-banner {
        padding-top: 132px;
        &.multiple {
            padding-top: 162px;
        }
    }
}
