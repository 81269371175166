@import '../../../styles/variables.scss';

.cart-block-header {
    // border-top: 1px solid $divider;
    // border-left: 1px solid $divider;
    // border-right: 1px solid $divider;
    background-color: rgba(0,0,0,0.02) !important;
}

@media only screen and (min-width: 768px) {
    // .cart-block-header {
    //     div {
    //         &:first-child {
    //             // width: 40% !important;
    //         }

    //         &:nth-child(2) {
    //             width: 30% !important;
    //             text-align: right !important;
    //         }

    //         &:last-child {
    //             // width: 30% !important;
    //         }
    //     }
    // }
}