@import '../../../styles/variables.scss';

.docs {
    display: flex;
    flex-direction: column;
    // margin-top: 20px;
    padding-left: 0;
    list-style-position: inside;

    a {
        flex-basis: auto;
        color: $primary;

        &:hover {
            color: $primary_dark;
        }
    }
}

.docs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .doc-item {
        max-width: 100px;
        img {
            max-width: 35px;
            max-height: 35px;
            margin: 0 auto;
            display: block;
        }
        span {
            text-align: center;
            word-wrap: break-word;
            font-size: 13px;
            display: block;
            margin-top: 5px;
        }
        margin: 5px;
    }
}

.techDocsTitle,
.sectionTitle {
    margin-top: 20px;
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
}

.sectionTitle {
    margin-top: 0px;
    margin-bottom: 10px;
}

.DescriptionTab {
    .description {
        font-size: 14px;
    }
}
