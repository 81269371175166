@import '../../../styles/variables.scss';

.product {
    .info {
        .content {
            h3 {
                font-size: 20px;
                &::after {
                    background-color: $primary;
                }
            }
        }
    }
}