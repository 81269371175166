@import '../../styles/variables.scss';

.show-quick-view {
    position: fixed;
    top: 0;
    z-index: 10000;
    left: 0;
    right: 0;
}

.popup-main-parent {
    width: 100%;
    // background: rgba(0,0,0,.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 10000;
    height: 100%;
}

.bannerParalax {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    padding-top: 160px;
    padding-bottom: 160px;
    color: white;
    background-color: #3a3d45;
    // background-image: url("../../assets/images/raval-trade.jpg");
    // padding-bottom: 0 !important;
    // padding-top: 0 !important;
    height: auto;
}

.overlay {
    padding-top: 120px;
    padding-bottom: 120px;
    //background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
}

.snackbarMessage {
    position: absolute;
    top: calc(100vh - 80px);
    background-color: $black;
    color: $white;
    padding: 20px;
    margin-left: 20px;
    animation: showSnackbar;
    animation-duration: 2s;
}

.passIsNotEqual {
    text-align: center;
    color: $red;
}

#resetPassword {
    .modal-body {
        min-height: 50px;
    }
    .resetingPassword {
        position: absolute;
        right: 50%;
    }
}

@keyframes showSnackbar {
    0% {
        top: 110vh;
    }
    100% {
        top: calc(100vh - 80px);
    }
}
