@import '../../../styles/variables.scss';

.deleteWatchDog {
    float: right;
    font-size: 2rem;

    &:hover {
        cursor: pointer;
        color: $primary;
        
    }
}

section.products.watchDogTable{
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.watchDogTable {

    position: relative;
    //shovany boreder co preteka skr paddingu
    overflow: hidden;
    min-height: 50px;

    .header {
        font-size: 2rem;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $divider;
    }

    .watchDogOnProduct {
        // border-bottom: 1px solid $divider;
        padding-bottom: 5px;
    }

    .setDogWatch {
        right: 50%;
        transform: translateX(50%);
    }
}

.products article .info {
    display: block;
}

@media only screen and (max-width: 992px) {
    section.watchDogTable {
        .products article .info{
            display: block !important;
        }
    }
}