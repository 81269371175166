@import '../../../styles//variables.scss';

footer {
    background-color: #ffffff;
    color: #7c7c7c;

    p {
        font-size: 14px;
    }

    .wrong {
        border-bottom-color: red;
    }
    // padding-top: 60px;

    p.description {
        margin-bottom: 25px;
    }

    .contactUs {
        a {
            color: $primary;
        }
        .icon {
            margin-right: 7px;
        }
    }

    .form-newsletter input {
        text-align: center;
    }


    // @rs
    .bottomMenu {
        text-align: center;
        font-size: 14px;

        div {
            display: block;
            text-align: center;
            margin: 15px 0;
        }
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid $primary;
        -webkit-text-fill-color: $primary;
        // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
        padding-left: 5px;
    }

    .btn-border {
        color: $primary;
        border-color: $primary;
    }

    .form-newsletter {
        .form-control {
            box-shadow: none;
        }
        .btn {
            margin-right: 5px;
        }

        input {
            outline: none !important;
        }

        .btnSubscribe {
            position: relative !important;
            top: 5px !important;
            transform: none !important;
        }
    }

    .footer-showroom {
        border-color: $divider;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .copyright {
        margin-bottom: 20px !important;
        margin-top: 40px !important;
    }
}
@media only screen and (min-width: 768px) {
    footer {

        .links {
            text-align: right;
        }

        .footer-showroom {
            padding-bottom: 0;
        }

        .form-newsletter input {
            text-align: left;
        }
    }
}

@media only screen and (min-width: 992px) {
    footer {
        .bottomMenu {
            div {
                display: inline-block;
                text-align: center;
                margin: 0;
                padding: 0 10px;
                border-right: 1px $gray solid;
                line-height: 1em;
            }
            div:last-child {
                border: 0;
            }
        }

        .footer-showroom {
            padding-bottom: 30px;
        }

        .cards {
            margin-top: 10px;
        }

        .copyright {
            margin-top: 10px;
        }
    }
}

@media only screen and (max-width: 576px) {
    footer {
        .footer-showroom {
            padding-bottom: 0 !important;
            padding-top: 20px !important;
        }
    }
}
