@import '../../../styles/variables.scss';

p.deliveryTypeDescription {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0;
}

.isNotFilled {
    color: $red;
    display: block;
    margin: 10px auto;
    text-align: center;
}

.invalid {
    outline-color: red !important;
    border-color: red !important;
    color: red !important;

    &::placeholder {
        color: red !important;
    }
}

.h4.deliveryAddressTitle {
    margin-top: 20px;
}

ul.weightCategories {
    list-style-type: none;
}

.countries {
    .form-group {
        border: 1px solid #ccc;
        padding: 12px 0px;
    }
    select {
        width: 100%;
        font-size: 14px;
        color: #555555;
        &:focus {
            outline: none;
        }
    }
}

select.classic2 {
    background-image: linear-gradient(45deg, transparent 50%, $black 50%),
        linear-gradient(135deg, $black 50%, transparent 50%);
    background-position: calc(100% - 17px) calc(1em - 5px), calc(100% - 12px) calc(1em - 5px);
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
}

select.classic2:focus {
    background-image: linear-gradient(45deg, transparent 50%, $black 50%),
        linear-gradient(135deg, $black 50%, transparent 50%);
    background-position: calc(100% - 17px) calc(1em - 5px), calc(100% - 12px) calc(1em - 5px);
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
}

.deliveryNoteBlock {
    position: relative;

    .loader {
        position: absolute;
        top: 50%;
        right: 50%;
    }
}

.btn {
    .disabled {
        background-color: lighten($primary, 33);
        pointer-events: none;
    }
}
