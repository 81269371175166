@import '../../../styles/variables.scss';

.navigation-main {
    font-family: 'Montserrat', sans-serif;

    .label {
        white-space: pre-wrap;
        text-align: left;
        line-height: 1.4;

        a {
            border-bottom: none !important;
            font-size: 1.4rem;
        }
    }

    ul li {
        cursor: pointer;
    }

    .logo {
        &:hover {
            cursor: pointer;
        }
    }

    .mini-banners {
        display: inline-flex;
        position: absolute;
        left: 25%;
        top: 22%;
        z-index: 1;

        &.multiple {
            left: 14%;
            justify-content: space-between;

            a:first-child {
                margin-right: 1rem;
            }
        }

        img {
            max-height: 30px;
            max-width: 210px;
        }
    }
}

.mini-banner-mobile-wrapper {
    background: $white;
    display: none;
}

nav .navigation-main .floating-menu > ul > li > a {
    // color: $primary;
    color: $gray;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: 0.2px;
}

nav .navigation-main .close-menu-wrapper,
nav .navigation-main .floating-menu > ul > li > a,
nav .navigation > ul {
    // border-bottom-color: $primary;
    border-bottom-color: $divider;
    // height: 34px;
}

nav .open-login,
nav .open-cart,
nav .open-settings,
nav .open-search {
    // border-left-color: $primary;
    // border-left-color: $gray;
    border-left-color: transparent;
}

.navbar-dropdown .col-md-4:nth-child(3n + 1) {
    clear: left;
}

nav.navbar-sticked {
    background-color: $white;
    box-shadow: 0px -2px 13px 0px $black;
}

nav .navigation-main .floating-menu > ul > li .navbar-dropdown .navbar-box .box-2 {
    background-color: $white;
}

nav .navigation-main .floating-menu > ul > li .navbar-dropdown li.label a {
    // color: $gray;
    color: $primary;

    &:hover {
        color: $primary;
    }
}

nav .navigation-main .floating-menu > ul > li .navbar-dropdown li a {
    color: $gray;
    border-bottom-color: $divider;

    &:hover {
        color: $primary;
    }
}

.subCateogry {
    font-size: 1.4rem;
}

// @rs desktop menu styling
.categories-menu-wrapper {
    width: 100vw;
    background-color: $primary;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 3.5em;

    .categories-menu {
        margin-left: 0;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        list-style: none;
        flex-direction: row;
        height: 100%;
        //max-width: 1200px;
        justify-content: flex-start;
        //width: 1140px;

        .menu-item {
            height: 100%;
            position: relative;

            a {
                color: $white;
                height: 100%;
                text-decoration: none;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding-left: 0.6em;
                padding-right: 0.6em;
                background-color: $primary;
                transition: background-color 0.3s ease-in;
                font-size: 14px;
                outline: none;

                &:hover {
                    background-color: darken($primary, 5);
                }
            }

            .navbar-dropdown {
                background: $white;
                display: none;
                position: absolute;
                padding: 1.25em;
                min-width: 100%;

                div {
                    width: 100%;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;

                    li {
                        width: 100%;

                        a {
                            line-height: 2em;
                            word-wrap: none;
                            white-space: nowrap;
                            font-size: 15.5px;
                            background: transparent;
                            color: $gray;

                            &:hover {
                                color: $primary;
                            }
                        }

                        &:not(:last-child) {
                            border-bottom: 1px solid $divider;
                        }
                    }
                }

                &:hover {
                    display: flex;
                }
            }

            .navbar-dropdown-trigger:hover + .navbar-dropdown {
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .navigation-main {
        background-color: $white;

        .mini-banners {
            left: 40%;
            img {
                max-height: 25px;
            }
        }
    }

    nav .navigation-main {
        .open-menu {
            color: $gray;
            border-left: none;

            .icon {
                line-height: 3;
            }
        }

        .close-menu {
            color: $gray;
        }

        .floating-menu {
            background-color: $white;

            .navbar-dropdown {
                background-color: initial;
                overflow: hidden;
                display: block;
            }
        }

        // svg.productSubcategory {
        //     fill: $gray;
        //     margin-right: 5px;
        // }
    }
}

@media only screen and (max-width: 1200px) {
    .categories-menu-wrapper {
        display: none;
        visibility: hidden;
    }

    .navigation-main {
        .mini-banners {
            left: 18%;
            top: 22%;
            z-index: 1;

            &.multiple {
                left: 0%;
                top: 80%;
            }

            img {
                max-width: inherit;
                max-height: 22px;
            }
        }
    }
}

@media only screen and (min-width: 991px) {
    nav .navigation-main {
        svg.productSubcategory {
            display: none;
        }

        .floating-menu {
            .navbar-dropdown {
                height: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .navigation-main {
        .mini-banners {
            left: 40%;
            top: 22%;
            z-index: 1;

            &.multiple {
                left: 35%;
                top: 22%;
            }

            img {
                max-width: inherit;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    nav .container {
        top: 1px !important;
    }
    .navigation-main {
        .mini-banners {
            left: 40%;
            top: 22%;

            &.multiple {
                left: 30%;
                top: 22%;
            }

            img {
                max-width: 175px;
            }
        }
    }
}
@media only screen and (max-width: 526px) {
    .navigation-main {
        .mini-banners {
            display: none
        }
    }
    .mini-banner-mobile-wrapper {
        display: block;
        text-align: center;
        padding: 10px 0;

        img {
            max-height: 33px;
        }

        &.multiple {
            display: flex;
            flex-direction: column;
            img {
                max-height: 24px;
            }
            a:first-child {
                margin-bottom: 1rem;
            }
        }

    }
}
