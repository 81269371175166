@import '../../styles/variables.scss';

.contactSection {
    padding-top: 0px;

    .map > div {
        min-height: 250px;
    }

    header {
        .title {
            color: $gray;
            padding-bottom: 30px;
        }
    }
}

.loadingBtn {
    right: 50%;
    transform: translateX(50%);
}

@media only screen and (min-width: 768px) {
    .contactSection {
        .map > div {
            min-height: 400px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .contactSection {
        padding-top: 75px;
    }
}
