@import '../../../styles/variables.scss';

.cart-wrapper.open {

    .cart-block.cart-block-item {
        width: 98% !important;

        .title {
            font-size: 14px !important;

            &:hover {
                cursor: pointer;
            }
        }

        .image {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .discountPrice {
        padding-bottom: 0 !important;
    }


    .cart-block-item {

        .title {
            float: left;
            width: calc(100% - 50px) !important;
            min-height: 50px;
        }

        .quantity {
            .form-control {
                height: 30px !important;
            }
        }

        .price {
            text-align: right;
            display: block;
            width: 100%;
            .h3 {
                font-size: 20px;
                right: 10px;
                position: absolute;
            }
        }

        .image {

            img {
                // max-width: 50px;
                width: auto;
                max-height: 50px;
                // height: auto;
            }

            &.empty {
                border: 1px solid black;
                width: 50px;
                height: 50px;
            }
        }
    }
}


.discountMessage {
    font-size: 11px;
    font-style: italic;
}

.form-quantity {
    text-align: right !important;
}

.cart-wrapper:not(.open) {
    .cart-block.cart-block-item {
        padding: 5px 0;
    }

    .quantity {
        .form-control {
            height: 30px !important;
        }
    }

    .title {

        &:hover {
            cursor: pointer;
        }
    }

    .image {
        &:hover {
            cursor: pointer;
        }
    }

    .totalPriceWithoutVat, .discountPrice > div {
        strong {
            font-size: 12px;
        }
    }
}

.removeItemFromShoppingCartLoader {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.cart-block-item {
    .image {
        text-align: center;
        img {
            max-width: 100px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .cart-block-item {
        .image {
            img {
                max-width: 50px;
                width: auto;
                // max-height: 50px;
                height: auto;
            }
        }

        .price {
            .h3 {
                font-size: 20px;
            }
        }

        .title {
            padding-left: 15px !important;
            text-align: left;
            .h4 {
                a {
                    font-size: 14px;
                    // line-height: 50px;
                }
            }
        }

        .quantity{
            width: auto !important;

            /*input.form-control {
                width: 30px;
            }*/
            input.form-control {
                padding-left: 5px;
                padding-right: 5px;
                width: 50px !important;
            }
        }

        .price {
            span {
                line-height: 38px;
            }

            padding-left: 10px !important;
        }
    }



    .cart-wrapper:not(.open) {
        .image {
            img {
                left: 50%;
                position: relative;
                transform: translateX(-50%);
            }
        }

        .icon-delete {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .cart-wrapper.open {
        .cart-block-item {
            div.quantity {
                input.form-control {
                    padding: 0 5px 0 5px;
                    width: 40px;
                }
            }
            .price {
                .h3 {
                    line-height: 40px;
                }
            }

        }
    }

    .cart-wrapper:not(.open) {
        .cart-block-item {

            .image {
                margin: 0 auto;
                max-width: 300px;
                padding-bottom: 15px !important;
                padding-top: 15px !important;
            }
        }

        .cart-block-item:last-child {
            margin-bottom: 15px;;
        }
    }
}

.coupon-discount {
    font-size: 80%;
    text-decoration: line-through;
    margin-top: -10px;
}
