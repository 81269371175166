@import '../../styles/variables.scss';

.not-found {
    background-color: white;
    height: calc(100vh);

    h1 {
        color: $primary;
        font-size: 55px;
    }

    p {
        font-size: 20px;
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }

    a {
        color: $primary;
        &:hover { 
            cursor: pointer;
        }
    }
}