@import '../styles/css/bootstrap.css';
@import '../styles/css/animate.css';
//@import '../styles/css/font-awesome.css';
@import '../styles/css/furniture-icons.css';
@import '../styles/css/linear-icons.css';
@import '../styles/css/magnific-popup.css';
@import '../styles/css/owl.carousel.css';
@import '../styles/css/ion-range-slider.css';
@import '../styles/css/theme.scss';
@import '../styles/variables.scss';

body {
    font-family: 'Montserrat', sans-serif !important;
}

.title {
    text-align: center;
}

.btn {
    border-radius: 0 !important;
}

.btnBlueBackground {
    background-color: $primary;
    color: $white;

    svg {
        fill: $white;
    }

    &:hover {
        background-color: $white;
        color: $primary;
        border-color: $primary;

        svg {
            fill: $primary;
        }
    }

    &.btn:focus {
        outline: none !important;
    }
}

.btnWhiteBackground {
    background-color: $white;
    color: $primary;
    border-color: $primary;
    svg {
        fill: $primary;
    }

    &:hover {
        background-color: $primary;
        color: $white;

        svg {
            fill: $white;
        }
    }

    &.btn:focus {
        outline: none !important;
    }
}

.btnOrangeBackground {
    background-color: $orange;
    color: $white;

    border-radius: 5px !important;

    svg {
        fill: $white;
    }

    &:hover {
        background-color: $white;
        color: $orange;
        border-color: $orange;

        svg {
            fill: $orange;
        }
    }

    &.btn:focus {
        outline: none !important;
    }
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.modal-backdrop {
    z-index: 10;
}

.text-sm {
    font-size: 1.5rem;
}

.text-bold {
    font-weight: bold;
}

.leading-sm {
    line-height: 1.25rem;
}

.bgGrayLight {
    background-color: $gray_light !important;
}

.bg-white {
    background-color: $white !important;
}

.displayHidden {
    display: none;
}

@media only screen and (max-width: 576px) {
    .main-header {
        padding-top: 90px !important;
        padding-bottom: 0 !important;
    }
}

.my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-2 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-4 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
}

.my-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 1.25rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 1.75rem;
}

.mb-5 {
    margin-bottom: 2rem;
}

.mb-10 {
    margin-bottom: 4rem;
}

.mb-15 {
    margin-bottom: 6rem;
}

.-mt-15 {
    margin-top: -6rem;
}

.pt-15 {
    padding-top: 6rem;
}

.py-10 {
    padding-top: 4rem;
}

.flex {
    display: flex;
}

.form-control {
    &:hover, &:focus {
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($primary, .6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($primary, .6);
    }

    &:hover {
        border-color: rgba($primary, .4);
    }

    &:hover:focus {
        border-color: $primary;
    }

}

html {
    scroll-behavior: smooth;
}
