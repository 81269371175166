@import '../../../styles/variables.scss';

.productItem {
    h2.title.h4 {
        text-align: left;
        font-size: 15px;
    }

    .image {
        min-height: 250px;
        max-height: 250px;
        height: auto !important;
        img {
            // width: 90% !important;
            height: auto !important;
        }
    }

    .prices {
        padding: 20px;

        sup {
            top: 0;
        }

        .price-show {
            color: $orange;
            font-size: 20px;

            &.fixedSet {
                position: relative;
                bottom: 20px;
            }
            &.fixedSets {
                position: relative;
            }
        }
    }

    .info {
        background-color: transparent;
        top: 40px !important;
    }

    .label-info {
        left: 20px !important;
        background-color: $red;
    }

    .addToShoppingCart {
        position: absolute;
        top: 50%;
        left: 75%;
        transform: translateX(-50%);
        &::after {
            border-color: $white transparent $white transparent;
        }
    }

    .add-favorite {
        &:hover {
            cursor: pointer;
        }
    }

    .watchDogText {
        padding-left: 20px;
        margin-bottom: 10px;
        font-size: 1rem;
        color: $primary;
    }

    span.retailPrice {
        font-size: 11px !important;
        text-decoration: line-through;
        color: $gray !important;
    }

    .discountedPrice {
        position: relative;
        font-size: 20px !important;
        color: $orange;
        display: block;
        bottom: 10px;

        span {
            position: absolute;
        }
    }

    .prices {
        padding: 10px 20px 10px 20px;
    }

    .productTags {
        width: calc(100% - 40px);
    }
}

.products article .label {
    position: static !important;
}

.products article .info {
    z-index: 1 !important;
}

.watchDogContainer {
    position: absolute;
    right: 0;
    bottom: 0px;
    padding-right: 20px;
}

.energyClasses {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.two {
        height: 73px;
    }

    img {
        max-height: 35px;
    }
}

.productTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 20px;
    justify-content: space-between;
    width: calc(100% - 40px);
    box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
    .productTags {
        position: absolute;
        bottom: 74%;
    }
    // .productItem {
    //     .label.label-info {
    //         position: absolute;
    //         bottom: 74%;
    //     }
    // }
}

@media only screen and (max-width: 767px) {
     .item-1, .item {
         max-width: 50%;
         margin: 0 25%;
     }
}
@media only screen and (max-width: 525px) {
     .item-1, .item {
         max-width: initial;
         margin: initial;
     }
}

@media only screen and (max-width: 768px) {
    .productItem {
        margin-bottom: 20px !important;

        .text {
            padding-left: 20px !important;
            padding-top: 20px !important;
        }

        .prices {
            padding: 10px 20px 10px 20px;
        }

        .productTags {
            width: 100%;
        }

        .info {
            top: 10px !important;
        }

        .image {
            width: 200px !important;
            height: 200px !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            img {
                max-width: 90% !important;
                max-height: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .productItem {
        .image {
            min-height: 170px;
            max-height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto !important;
            height: auto !important;
            img {
                width: auto !important;
                max-height: 150px !important;
            }
        }
    }

    .products {
        padding-top: 20px !important;
    }
}
