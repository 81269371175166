@import '../../styles/variables.scss';

.thankYouPage {
    // height: calc(100vh );
    .main-header {
        background-color: $white;

        header {
            color: $primary;
        }
    }

    // .content {
    //     height: 100vh;
    // }
}