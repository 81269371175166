@import '../../../styles/variables.scss';

.numStars {
    margin-left: 10px;
}

.hodnotenie {
    display: flex;
    flex-direction: row;
}

.yellowColor {
    color: $stars;
}