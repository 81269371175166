@import '../../../styles/variables.scss';

.totalPriceWithVat {
    font-size: 20px;
}
.note-block {
    .white-block {
        padding: 30px 30px 15px 30px;
    }
}

@media only screen and (max-width: 568px) {
    .sumaryPriceBlock .form-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            padding-left: 5px;
        }
    }
}

.note {
    white-space: pre-wrap;
    word-break: break-word;
    text-align: justify;
}
