@import '../../../styles/variables.scss';

.nav-tabs > li.active > a, .nav-tabs > li:hover > a {
    box-shadow: 0px -5px 0px $primary;
}

.nav-tabs li {
    font-size: 14px;
}

ul.nav.nav-tabs {
    display: inline-flex;
    overflow: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

}

@media only screen and (max-width: 576px) {
    ul.nav.nav-tabs {
        display: inline-flex;
        overflow: auto;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

        li > a {
            height: 100%;
        }
    }

    .tab-content {
        /*padding-bottom: 20px !important;
        padding-top: 20px !important;*/

        .content {
            padding-bottom: 0 !important;
        }
    }
}

// @media only screen and (max-width: 992px) {
//     ul.nav.nav-tabs {
//         bottom: 100px;
//         position: relative;
//     }
// }
