@import '../../styles/variables.scss';

.compareProducts {
    .panel {
        box-shadow: none;
        border: none;
    }

    .panel-body {
        padding: 0;
    }

    .main-header {
        background-color: $white;
        padding-top: 140px;

        h1 {
            color: $primary;
        }
    }

    section:nth-child(2) {
        padding-top: 20px;
    }

    .panel-body {
        overflow: auto;

        table {
            tbody {
                position: relative;
                font-size: 13px;
                tr {
                    td {
                        min-width: 200px;
                        // vertical-align: middle;
                        position: sticky;

                        &:first-child {
                            // position: absolute;
                            position: sticky;
                            // left: 0;
                            background-color: $white;
                            // background-color: red;
                            height: 20%;
                            z-index: 1;
                        }
                    }
                }

                tr {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .averageNumberCompare {
        margin-left: 5px;
    }

    .compageIcon {
        display: block;
        text-align: center;
        margin-bottom: 20px;

        svg {
            fill: $gray;
        }
    }
}

.removeProductFromCompare {
    float: right;
    position: absolute;
    right: 0;
    top: 3px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        color: $primary;
    }
}

.productTitle span:first-child {
    margin-right: 5px;

    &:hover {
        cursor: pointer;
        color: $primary;
    }
}

#tables {
    min-height: 50px;
}

.reloadCompareProduct {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.imageRow {
    &:hover {
        cursor: default;
        background-color: inherit !important;
    }
}

.compareAssetImage {
    text-align: left;
    img {
        max-width: 100px;
        height: auto;

        &:hover {
            cursor: pointer;
        }
    }
}

.addToShopingCartFromCompare {
    &:hover {
        cursor: pointer;
        color: $primary;
    }
}

@media only screen and (max-width: 568px) {
    .compareProducts {
        .panel-body {
            table {
                tbody {
                    tr {
                        td {
                            min-width: 100px;
                            &:first-child {
                                // position: absolute;
                                display: table-cell;
                                position: sticky;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .compareProducts {
        padding-top: 2em;
    }
}
