@import '../../../styles/variables.scss';

.products article .info {
    z-index: 10;
}

.owl-product-gallery {
    // @rs disabled in order to allow scroll on phones
    // touch-action: none;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    -webkit-touch-callout: manipulation;
}

.popup-main {
    .mfp-close {
        border-left: none;
        height: 60px !important;
    }

    .product {
        .title {
            text-align: left;
        }

        .owl-product-gallery {
            max-height: 400px;
            img {
                max-height: 400px;
                width: auto;
                display: block;
                margin: 0 auto;
                padding-bottom: 20px;
            }
        }

        .watchDogQuickView {
            position: absolute;
            right: 20px;
            background-color: #696969;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            z-index: 2;

            a:hover {
                &::after {
                    position: absolute;
                    content: attr(data-title);
                    padding: 5px 6px;
                    right: 110%;
                    top: 3px;
                    white-space: nowrap;
                    z-index: 20;
                    background-color: #000000;
                    color: #fff;
                    font-size: 10px;
                    border-radius: 5px;
                    line-height: normal;
                }
            }

            i {
                color: $white;
                top: 50%;
                position: absolute;
                right: 50%;
                transform: translate(50%, -50%);
            }

            &:hover {
                background-color: $black;
                color: $white;
                cursor: pointer;
            }
        }
    }

    .info-box {
        display: block;
        strong {
            // width: 100px;
            padding-right: 5px;
        }
    }

    .popup-cell {
        background-color: $white;
    }

    .popup-buttons a {
        background-color: $gray_light;

        span {
            margin-left: 5px;
        }

        &:hover {
            cursor: pointer;

            svg {
                transition: all 0.3s;
                fill: $white;
            }
        }
    }

    .popup-cell:nth-child(1) {
        color: $primary;
        padding: 0 0 0 40px !important;
    }

    .addToShoppingCart {
        position: absolute;
        top: calc(100% - 60px);
        right: 18px;
    }

    a:hover.aWithoutHover {
        background-color: $gray_light;
        cursor: default;
    }
}

.popup-title {
    width: calc(100% - 64px);
}

.popup-content {
    padding-top: 20px !important;

    .product-info-wrapper {
        font-size: 1.4rem;

        .productDescription {
            margin-bottom: 10px !important;
        }

        .info-box {
            width: 100% !important;
            font-size: 12px;
        }
    }

    &.quick-view-tags {
        background-color: transparent;
        padding: 10px 15px;
        padding-top: 10px !important;

        .oneTag {
            font-size: 75%;
            padding: 3px 10px;
        }
    }
}

// @rs
// showing full detail
.info-box {
    &.description {
        max-height: 140px;
        overflow: hidden;
        position: relative;
        transition: all 7.5s ease;

        &:not(.full) {
            span {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                max-height: 105px;
                margin-bottom: 2em;
            }
        }

        .showFullTrigger {
            position: absolute;
            top: 125px;
            color: $primary;
            transition: all 0.3s ease;

            &:hover {
                text-decoration: underline;
            }
        }

        &.full {
            max-height: 9999px !important;
            .showFullTrigger {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .popup-main .popup-cell:nth-child(1) {
        padding: 0 15px !important;
    }

    .popup-main .popup-buttons a {
        padding: 15px;
    }

    // @rs
    // display the content text as a fullwidth to optimize space usage,
    // add spacing below headers and between the first two wrappers (availability and product info)
    .product-info-wrapper {
        .row {
            display: flex;
            flex-direction: column;

            div {
                width: 100% !important;

                &:first-child {
                    margin-bottom: 0.5em;
                }

                strong {
                    margin-bottom: 0.15em;
                }
            }
        }
    }
}
