//@import '../../index.scss';

.text-red {
  color: red;
}


.input-label {
  letter-spacing: .15px;
  font-size: 1.5rem !important;
  margin-bottom: 3px;
}

.fieldset {
  //border: 1px $lightGray solid;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-weight: bold;
  }

  .w-full {
    width: 100%;
  }

  .w-1\/2 {
    width: 50%;
  }

  .w-1\/5 {
    width: 20%;
  }

  .w-4\/5 {
    width: 80%;
  }
}


.p-4 {
  padding: 0.75rem;
}

@import '../../styles/variables.scss';

.article {
  background-color: $gray_light;
  padding-top: 0px;

  .content {
    // background-color: $white;
    // padding: 20px;

    img {
      max-width: 100%;
    }
  }

  header {
    .title {
      color: $gray;
      padding-bottom: 30px;
    }
  }

  .section-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ql-align-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .article {
    padding-top: 75px;
  }
}
