@import '../../styles/variables.scss';

// .owl-product-gallery {
//     // padding: 60px;
// }

.productDetail {
    background-color: $white;
    padding-top: 150px;

    .title {
        color: $primary;
    }

    .breadcrumb li a {
        color: $gray;

        &.active {
            color: $primary;
        }
    }

    .breadcrumb {
        white-space: pre-wrap;
        a:hover {
            color: $primary;
            cursor: pointer;
        }
    }
}

.product {
    .titleWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        vertical-align: middle;
        margin-bottom: 1.6em;

        .title {
            font-size: 22px !important;
            margin: 0;
        }
    }

    .price:nth-child(2) {
        span:nth-child(1) {
            font-size: 12px !important;
        }

        span:nth-child(2) {
            font-size: 16px !important;
        }
    }

    .tabs {
        margin-top: -81px;
    }
    @media screen and (min-width: 992px) {
        .tabs {
            margin-top: -83px;
        }
    }
}

span.oneTag {
    margin-right: 5px;
    border-radius: 15px;
    padding: 5px 15px;
    color: $white;
    font-size: 13px;
}

.giftsContainer {
    strong {
        padding-bottom: 20px;
    }

    .gifts {
        margin-top: 10px;
        position: relative;
    }
    .oneGift {
        display: flex;
        flex-direction: row;

        .giftImage {
            width: 50px;
            img {
                width: 50px;
            }
        }

        .giftName {
            align-self: center;
            padding-left: 10px;
            font-size: 13px;
        }
    }
}

.product {
    .btnCompare {
        svg {
            transition: all 0.3s;
            margin-right: 5px;
        }

        &:hover {
            svg {
                fill: $white;
            }
        }
    }

    .price {
        .h3 {
            color: $orange !important;
        }
        &.withoutVat {
            .h3 {
                color: $gray !important;
                font-weight: normal;
            }
        }
    }

    .alignCenter {
        button {
            display: block;
            margin: 0 auto;
        }
    }

    .availability {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: space-between;

        span:last-child {
            font-weight: bold;
            color: $primary;
        }
    }

    .coupon {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        color: $green;
        font-weight: bold;

        .h3 {
            margin: 0;
            font-size: 14px;
            color: $orange;
        }
    }

    .energyClass {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -12px 0;

        img {
            height: 40px;
        }
    }
}

.emptyGallery {
    margin: 0 auto;
    height: 500px;
    max-width: 500px;
    border: 1px solid black;

    span {
        display: flex;
        justify-content: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.addingToShoopingCart {
    position: relative !important;
}

//TODO: mozno upravit
@media only screen and (max-width: 992px) {
    section.product {
        .info {
            margin-top: 58px;
            //.nav-tabs {
            //    margin-top: -63px;
                //position: absolute;
            //}
        }
    }
}
