@import '../../../styles/variables.scss';

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    min-width: 250px;
    /* Set a default minimum width */
    margin-left: -125px;
    /* Divide value of min-width by 2 */
    background-color: #333;
    /* Black background color */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Centered text */
    border-radius: 2px;
    /* Rounded borders */
    padding: 16px;
    /* Padding */
    position: fixed;
    /* Sit on top of the screen */
    z-index: 1000000000000;
    /* Add a z-index if needed */
    left: 50%;
    /* Center the snackbar */
    bottom: 30px;
    /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.show {
    position: fixed;
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s forwards;
    animation: fadein 0.5s forwards;
    z-index: 10000000;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

.hidde {
    position: fixed;
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadeout 0.5s;
    animation: fadeout 0.5s;
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.customSnackbar {
    //   background-color: $black;
    //   color: $white;
    background-color: $primary;
    color: $white;
    min-width: 100px !important;
    padding: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    font-size: 14px;

    &.error {
        background-color: $red;

        .snackbarAction {
            background-color: $red;
        }
    }
}

.snackbarAction {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid white;
    color: white;
    background-color: $primary;

    span {
        font-weight: 900;
        font-size: 1.1em;
        margin-bottom: -3px;
    }
}
