@import '../../../styles/variables.scss';

.search-results {
    overflow: auto;
    max-height: 50vh;

    padding: 0 !important;
    .search-result-items {
        margin-bottom: 0 !important;

        a:hover {
            cursor: pointer;
            color: $primary;
        }
    }

    &.setPadding {
        padding: 15px !important;
    }
}

.search-wrapper {
    .form-control {
        &:focus {
            box-shadow: none;
        }
    }
}

.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media (max-width: 768px) {
        min-height: calc(100vh - 20px);
    }
}

#registerModal {
    .form-control {
        &:focus {
            box-shadow: none;
        }

        &.wrong {
            border-color: $red;
        }
    }

    .passHelper {
        display: none;
    }
    .passHelper.wrongMinLength,
    .passHelper.passIsNotEqual,
    .passHelper.emailNotValid,
    .passHelper.emailNotValid {
        display: block;
        color: $red;
        font-size: 16px;
        text-align: center;
    }

    .registerLoader {
        display: block;
        margin: 0 auto;
        position: relative;
        top: auto;
        right: auto;
    }
}

.login-wrapper {
    form {
        a {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .socialBtns {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        .socialLogin {
            font-size: 13px;
            padding-right: 20px;
            display: flex;
            margin-bottom: 5px;
            align-items: center;
            flex-grow: 1;
        }

        .googleBtn {
            div {
                border-radius: 0 !important;
            }
            // div,
            // span {
            //     padding-top: 0 !important;
            //     padding-bottom: 0 !important;
            //     padding-right: 0 !important;
            // }
            // div {
            //     position: relative;
            //     top: 2px;
            // }
            width: 40px;
            height: 45px;
        }

        .googleBtn + span {
            width: 40px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: rgb(60, 90, 153);
                border-radius: 4px;
                color: $white;
                text-align: center;
                height: 28px;
                height: 40px;
                border: 1px solid transparent;

                i {
                    font-size: 18px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }
}

.clearIcon {
    position: absolute;
    right: 20px;
    top: 22px;

    &:hover {
        cursor: pointer;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 12px;
}
.lds-dual-ring:after {
    content: ' ';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid $primary;
    border-color: $primary transparent $primary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* ked ked viac prvkov v kosiku aby boli skrolovatelne */
.cart-wrapper.open {
    overflow: auto;
    max-height: 50vh;
}

nav.navbar-fixed.navbar-sticked {
    z-index: 99 !important;
}

nav {
    z-index: 9 !important;
}
