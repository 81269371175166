@import '../../../styles/variables.scss';

.owl-product-gallery {
    img {
        max-width: 300px;
        height: 100%;
        display: block;
        margin: 0 auto;
        max-height: 333px;
        object-fit: contain;
    }

    img[data-src].lazyOwl {
        display: none;
    }
}
