@import '../../../styles/variables.scss';

.categories {
    padding: 0 !important;
}

.search-results {
    a:hover,
    a:not(:hover) {
        .categories {
            color: $gray;
            font-style: italic;
            font-size: 1.25rem;
            text-align: right;
            float: right;
        }
    }
}

// @rs hotfix searchbar sizing
nav .search-wrapper .form-control {
    box-sizing: border-box;
    height: 4.01em;
}

@media screen and (max-width: 991px) {
    nav .search-wrapper .form-control {
        height: 4.5em;
    }
}

@media (max-width: 767px) {
    .search-results {
        a:hover,
        a:not(:hover) {
            .categories {
                font-size: 1.15rem;
                text-align: left;
                float: left;
            }
        }
    }
    nav .search-wrapper .form-control {
        height: 2em;
    }

    nav .search-wrapper {
        div {
            .clearIcon {
                top: 14px;
            }
        }
        .lds-dual-ring {
            top: 5px;
        }
    }
}

// @rs this cant be here, since it causes side effects (displaying empty container when there are no results to be shown)
// nav .search-wrapper .search-results .search-result-items {
//     margin-bottom: 20px !important;
// }
