@import '../../../styles/variables.scss';

.fixedSetDetailContent {
    font-size: 14px;
}

.fixedSetProductImage {
    max-width: 300px;
    display: block;
    margin: 0 auto;
}