@import '../../../styles/variables.scss';

p.paymentTypeDescription {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0;
}

.note-block {
    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
    }
}