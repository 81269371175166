@import '../../../styles/variables.scss';

.info-box > span:last-child {
    width: auto;
    margin-left: 10px;
}

.info-box.parameters {
    display: block;

    span {
        font-size: 13px !important;
    }
   
    span.parameterName {
        font-size: 13px !important;
        strong {
            margin-right: 5px;
        }
    }
}

.info-box {
    width: 50% !important;
}
