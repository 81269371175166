@import '../../../styles/variables.scss';

#inviteFriendDialog {
    .modal-body {
        min-height: 60px;

        .setDogWatch {
            right: 50%;
            transform: translateX(50%);
        }
    }

    .form-group.watchPrice {
        display: inline-block;

        .form-control {
            width: 100px;
            display: inline-block;
            height: 26px;
            padding: 5px !important;
            margin: 0 5px;
            
        }
    }

    .activeWatchDog {
        float: right;
        margin: 20px 0;
    }
}