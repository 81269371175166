@import '../../../styles/variables.scss';

#objednavky {
    overflow: auto;
}
.myOrders {
    min-width: 576px;
    padding-bottom: 20px;

    span {
        font-size: 12px;
    }
    
    .header {
        display: flex;
        justify-content: space-between;
        background-color: $primary;
        color: $white;
        line-height: 3;
        text-align: center;


        :last-child {
            text-align: right;
            position: relative;
            min-width: 120px;

            span {
                padding-right: 10px;
            }
        }
    }

    .orderRow {
        display: flex;
        justify-content: space-between;
    }

    .orderDetailRow {
        text-align: center;
        line-height: 3;
        border-bottom: 1px solid $divider;
        &:hover {
            cursor: pointer;
        }
        :last-child {

            span {
                padding-right: 10px;
            }
        }


    }
}

@media only screen and (max-width: 992px) {
    .myOrders {
        .header {
            line-height: 2;
        }
    }
}