@import '../../../styles/variables.scss';

.filters .filter-box .title {
    line-height: 1.3;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters .filter-box {
    span {
        label {
            font-size: 12px;
        }
    }
}


.checkbox input[type="checkbox"]:checked+label > .paramProductCount,
.checkbox input[type="radio"]:checked+label > .paramProductCount {
    display:none;
}
.checkbox.disabled input[type="checkbox"]:checked+label:before,
.checkbox.disabled input[type="radio"]:checked+label:before {
    border-color: $primary_dim;
    background-color: $primary_dim;
}

.checkbox.disabled input[type="checkbox"]+label,
.checkbox.disabled input[type="radio"]+label {
    color: $shade !important;
}
.checkbox input[type="checkbox"]:checked+label,
.checkbox input[type="radio"]:checked+label {
    font-weight: bold;
}
