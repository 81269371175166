@import '../../styles/variables.scss';

.owl-product-gallery {
    // padding: 60px;
}

.productDetail {
    background-color: $white;
    padding-top: 110px;
    padding-bottom: 0;

    .title {
        color: $black;
        font-size: 22px;
    }

    .breadcrumb li a {
        color: $gray;

        &.active {
            color: $primary;
        }
    }
}

.product {
    .btnCompare {
        svg {
            transition: all 0.3s;
            margin-right: 5px;
        }

        &:hover {
            svg {
                fill: $white;
            }
        }
    }

    .price {
        justify-content: space-between;
        display: flex;

        /* 
        span:first-child {
            font-size: 12px;
        }
        */

        .h3 {
            color: $primary;
            margin: 0;
        }
    }

    .alignCenter {
        button {
            display: block;
            margin: 0 auto;
        }
    }

    .main {
        padding-top: 20px;
    }
}

.emptyGallery {
    margin: 0 auto;
    height: 500px;
    max-width: 500px;
    border: 1px solid black;

    span {
        display: flex;
        justify-content: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (min-width: 1200px) {
    .fixedSetDetail {
        padding-top: 175px;
    }
}
